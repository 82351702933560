import { useCallback } from "react"
import { useBreakpoint } from "../lib/breakpoint"

export function ChatInWhatsApp() {
  const { isBreakpoint } = useBreakpoint()
  const handleClick = useCallback(() => {
    const number = "16048176108"
    const message = "www.millestudios.com\n\nHi, Shannon! I'm interested in learning more about Mille Studios. "
    // const link = isBreakpoint("sm")
    //   // Desktop
    //   ? `https://web.whatsapp.com/send?phone=${number}&text=${encodeURIComponent(message)}`
    //   // Mobile
    //   : `https://wa.me/${number}?text=${encodeURIComponent(message)}`
    const link = `https://wa.me/${number}?text=${encodeURIComponent(message)}`
    window.open(link, "_blank")
  }, [])
  return <>
    {!isBreakpoint("sm") && <div className="text-white">
      <button className="btn-outline-white border-gray-300 bg-black/50" onClick={handleClick}>
        <i className="bi bi-whatsapp" /> WhatsApp
      </button>
    </div>}
  </>
}