import { useEffect, useState } from "react"
import { useAppSelector } from "../store/store"

const volumes = [
  "volume-off",
  "volume-down",
  "volume-up",
]

export function AnimatedBars() {
  const [volumeIndex, setVolumeIndex] = useState(0)
  const isPlaying = useAppSelector(state => state.music.isPlaying)

  useEffect(() => {
    if (!isPlaying) return
    const timer = setInterval(() => setVolumeIndex(i => (i >= volumes.length - 1) ? 0 : i + 1), 500)
    return () => {
      clearInterval(timer)
    }
  }, [isPlaying])

  useEffect(() => {
    if (!isPlaying) setVolumeIndex(0)
  }, [isPlaying])

  return <i className={`${isPlaying ? "text-white" : "text-gray-700"} bi-${volumes[volumeIndex]}-fill`} />
}