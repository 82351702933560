import React, { useContext, useEffect, useState } from "react"
import { load, ReCaptchaInstance } from "recaptcha-v3"

export const RecaptchaContext = React.createContext<ReCaptchaInstance | null>(null)


export function useRecaptchaInstance() {
  const [recaptcha, setRecaptcha] = useState<ReCaptchaInstance | null>(null)
  useEffect(() => {
    let unloaded = false
    async function loadRecaptcha() {
      const recaptcha = await load('6Le1qvofAAAAAPmUK_t1_rwdaCfWQw6NrIcbYY4Y',
        {
          renderParameters: { badge: "bottomleft" }
        }
      )
      if (unloaded) {
        recaptcha.hideBadge()
        return
      }
      recaptcha.showBadge()
      setRecaptcha(recaptcha)
    }
    loadRecaptcha()
    return () => {
      unloaded = true
    }
  }, [])

  useEffect(() => {
    if (!recaptcha) return
    return () => {
      recaptcha.hideBadge()
    }
  }, [recaptcha])
  return recaptcha
}


export function useRecaptcha() {
  const recaptcha = useContext(RecaptchaContext)
  return recaptcha
}