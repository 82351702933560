import { useLocation } from "react-router-dom"
import { useScrollToTop } from "../lib/scrollToTop"
import { AppRoutes } from "./AppRoutes"
import { ChatInWhatsApp } from "./ChatInWhatsapp"
import { PageFooter } from "./PageFooter"
import { PageHeader } from "./PageHeader"
import { Player } from "./Player"
import { PlayerShowWrapper } from "./PlayerShowWrapper"

export function MainLayout() {
  useScrollToTop()
  const location = useLocation()
  const isRoot = location.pathname === "/"

  return <div className="relative">
    {/* <div className="absolute top-0 left-0 w-full"> */}
    <div className={`${isRoot ? "absolute top-0 w-full z-20" : ""}`}>
      <PageHeader />
    </div>
    <div className={`${isRoot ? "absolute top-0 w-full z-10" : ""}`}>
      <AppRoutes />
      <div className={`${isRoot ? "" : "mt-12"}`}>
        <PageFooter />
      </div>
    </div>
    <div className="fixed bottom-5 right-5 z-30 grid gap-2">
      <div className="flex justify-end">
        <ChatInWhatsApp />
      </div>
      <PlayerShowWrapper>
        <Player />
      </PlayerShowWrapper>
    </div>
  </div>
}