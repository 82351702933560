import React from "react"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"


type FadeInViewProps = {
  children?: React.ReactNode
}
/** Fade in children when in view. */
export function FadeInView({ children }: FadeInViewProps) {
  const { ref, inView } = useInView()
  return <motion.div
    transition={{
      delay: 0,
      duration: 0.8,
    }}
    animate={{
      opacity: inView ? 1 : 0,
    }}
    ref={ref}>
    {children}
  </motion.div>
}