import React, { useEffect, useState } from "react"
import { Breakpoint, breakpointContext, breakpoints } from "../lib/breakpoint"

type BreakpointProviderProps = {
  children?: React.ReactNode
}
export function BreakpointProvider({ children }: BreakpointProviderProps) {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>("sm")
  useEffect(() => {
    function watchBreakpoint() {
      const breakpoint = breakpoints.find(breakpoint =>
        !!(document.getElementById(`breakpoint-${breakpoint}`)?.offsetParent) || false
      ) || "xs"
      // console.log(breakpoint)
      setBreakpoint(breakpoint)
    }
    watchBreakpoint()
    window.addEventListener("resize", watchBreakpoint)
    return () => {
      window.removeEventListener("resize", watchBreakpoint)
    }
  }, [])
  return <>
    <div id="breakpoint-sm" className="hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden w-0 h-0"></div>
    <div id="breakpoint-md" className="hidden sm:hidden md:block lg:hidden xl:hidden 2xl:hidden w-0 h-0"></div>
    <div id="breakpoint-lg" className="hidden sm:hidden md:hidden lg:block xl:hidden 2xl:hidden w-0 h-0"></div>
    <div id="breakpoint-xl" className="hidden sm:hidden md:hidden lg:hidden xl:block 2xl:hidden w-0 h-0"></div>
    <div id="breakpoint-2xl" className="hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:block w-0 h-0"></div>
    <breakpointContext.Provider value={breakpoint}>
      {children}
    </breakpointContext.Provider>
  </>
}