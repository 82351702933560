import { useCallback, useEffect, useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { useMainPages } from "../lib/wp"
import { PlayerMini } from "./PlayerMini"

export function PageHeader() {
  const [collapsed, setCollapsed] = useState(true)
  const { data, error } = useMainPages()
  // console.log(data)
  // console.log(error)

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  const handleLinkClick = useCallback(() => {
    setCollapsed(true)
  }, [])

  return <div className="bg-black/70 text-white">
    <div className="container mx-auto py-2 flex flex-wrap items-center gap-3">
      <Link className="" to="/">
        <img src="/assets/logo/ms-logo-final2-darkbg-20220423.png" className="h-14" alt="" />
      </Link>
      <div className="grow"></div>
      <PlayerMini />
      <div className="block lg:hidden">
        <button className="px-3 py-3 leading-none text-xl" onClick={() => setCollapsed(v => !v)}>
          <i className="bi-three-dots-vertical"></i>
        </button>
      </div>
      <div className={`${collapsed ? "hidden" : ""} w-full lg:w-auto grid lg:flex flex-wrap text-sm items-center`}>
        <NavLink
          to="/"
          className={({ isActive }) => `header-link ${isActive ? "active" : ""}`}
          onClick={handleLinkClick}
        >
          Home
        </NavLink>
        {data
          ?.filter((page: any) => !page.acf.hide_in_menu)
          .sort((a: any, b: any) => a.date < b.date ? -1 : 1)
          .map((page: any) =>
            <NavLink
              to={`/${page.slug}`} key={page.id} className={({ isActive }) => `header-link ${isActive ? "active" : ""}`}
              onClick={handleLinkClick}
            >{page.acf.menu_label || page.title.rendered}</NavLink>)}
        <a
          className="header-link"
          href="https://blog.millestudios.com/shop-welcome/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleLinkClick}
        >
          Store <i className="bi bi-box-arrow-up-right" />
        </a>
        <a
          className="header-link"
          href="https://blog.millestudios.com"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleLinkClick}
        >
          Blog <i className="bi bi-box-arrow-up-right" />
        </a>
        <NavLink
          to="/contact"
          className="header-link"
          onClick={handleLinkClick}
        >
          Book Today
        </NavLink>
      </div>
    </div>
  </div>
}