import { Route, Routes } from "react-router-dom"
import { useMainPages } from "../lib/wp"
import { Contact } from "../routes/Contact"
import { Home } from "../routes/Home"
import { WpPage } from "./WpPage"


export function AppRoutes() {
  const { data, error } = useMainPages()
  return <Routes>
    {data?.map((page: any) => <Route key={page.id} path={`/${page.slug}`} element={<WpPage slug={page.slug} id={page.id} />} />)}
    <Route path="/" element={<Home />} />
    <Route path="/contact" element={<Contact />} />
  </Routes>

}