import { useCallback } from "react"
import { musicActions } from "../store/slices/music"
import { useAppDispatch, useAppSelector } from "../store/store"
import { AnimatedBars } from "./AnimatedBars"

/** Player for the header */
export function PlayerMini() {
  const playing = useAppSelector(state => state.music.isPlaying)
  const dispatch = useAppDispatch()
  const handlePlayClick = useCallback(() => {
    dispatch(musicActions.toggleIsPlaying())
  }, [dispatch])
  return <div className="flex gap-2 items-center">
    <button className="text-lg" onClick={handlePlayClick}>
      {/* <i className="bi-volume-down" /> */}
      {playing
        ? <i className="bi-pause-circle" />
        : <i className="bi-play-circle" />
      }
    </button>
    <AnimatedBars />
  </div>
}