import React from "react"
import { useAppSelector } from "../store/store"

type PlayerShowWrapperProps = {
  children?: React.ReactNode
}
export function PlayerShowWrapper({ children }: PlayerShowWrapperProps) {
  const showPlayer = useAppSelector(state => state.music.showPlayer)
  if (!showPlayer) return null
  return <>{children}</>
}