import { createSlice, PayloadAction } from "@reduxjs/toolkit"



type MusicState = {
  isPlaying: boolean
  showPlayer: boolean
}

const initialState: MusicState = {
  isPlaying: false,
  showPlayer: true,
}

const slice = createSlice({
  name: "music",
  initialState,
  reducers: {
    setIsPlaying: (state, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload
    },
    toggleIsPlaying: (state) => {
      state.isPlaying = !state.isPlaying
    }
  }
})


export const musicReducer = slice.reducer
export const musicActions = slice.actions