import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import ss001 from "../assets/shannon/shannon-splash001-dark.jpg"

export function SplashMain() {
  return <div className="min-h-screen bg-black text-white relative">
    <div className="absolute top-0 left-0 w-full h-full bg-cover bg-top"
      style={{ backgroundImage: `url(${ss001})` }}
    >
    </div>
    <motion.div
      className="absolute top-0 left-0 w-full h-full bg-black"
      transition={{ duration: 8 }}
      initial={{ opacity: 1 }}
      animate={{ opacity: 0.3 }}
    />
    <div className="container mx-auto relative top-0 left-0 w-full">
      <div className="pt-32 sm:pt-48 lg:pt-64 flex justify-end">
        <div className="overflow-hidden">
          <motion.div
            className=""
            transition={{ duration: 2 }}
            initial={{ opacity: 0, translateX: 30 }}
            animate={{ opacity: 1, translateX: 0 }}
          >
            <div className="grid gap-3 mx-5 my-5">
              <h1 className="text-5xl leading-tight">
                Sing. Perform.<br />
                Explore your sound.
              </h1>
              <motion.div
                transition={{
                  duration: 2,
                  delay: 2,
                }}
                initial={{ opacity: 0, translateX: 30 }}
                animate={{ opacity: 1, translateX: 0 }}
              >
                <div className="">
                  <Link to="/contact" className="btn-outline-white">Book Today</Link>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </div >
  </div >
}