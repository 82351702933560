import React, { useCallback, useEffect, useRef, useState } from "react"
import { musicActions } from "../store/slices/music"
import { useAppDispatch, useAppSelector } from "../store/store"

export function Player() {
  const playing = useAppSelector(state => state.music.isPlaying)
  const dispatch = useAppDispatch()
  // const [playing, setPlaying] = useState(false)
  const [playPercent, setDisplayPercent] = useState(0)
  const [loaded, setLoaded] = useState(false)

  const audioRef = useRef<HTMLAudioElement>()

  const loadMusic = useCallback(() => {
    setLoaded(false)
    audioRef.current = new Audio("/assets/music/is-that-alright-shannon-chung-preview.mp3")
    audioRef.current.addEventListener("ended", () => dispatch(musicActions.setIsPlaying(false)))
    audioRef.current.addEventListener("canplay", () => setLoaded(true))
  }, [dispatch])

  const updateDisplayPercent = useCallback(() => {
    if (audioRef.current && !isNaN(audioRef.current.duration) && audioRef.current.duration !== Infinity)
      setDisplayPercent(audioRef.current.currentTime / audioRef.current.duration)
  }, [])

  useEffect(() => {
    const interval = setInterval(updateDisplayPercent)
    return () => {
      clearInterval(interval)
    }
  }, [updateDisplayPercent])

  useEffect(() => {
    if (playing) {
      if (!audioRef.current) loadMusic()
      if (audioRef.current) audioRef.current.play()
    } else {
      if (audioRef.current) audioRef.current.pause()
    }
  }, [loadMusic, playing])

  const handleSeekClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left; //x position within the element.
    // const y = e.clientY - rect.top;  //y position within the element.
    const percent = x / rect.width
    setDisplayPercent(percent)
    if (!audioRef.current) loadMusic()
    if (audioRef.current && !isNaN(audioRef.current.duration) && audioRef.current.duration !== Infinity) {
      audioRef.current.currentTime = percent * audioRef.current.duration
      updateDisplayPercent()
    }
  }, [loadMusic, updateDisplayPercent])

  return <div className="bg-black/80 text-white border border-gray-600/70 grid divide-y divide-gray-600/70 shadow-md w-[250px]">
    <div className="px-2 pt-2 pb-0.5">
      <div className="grid gap-1">
        <div className="flex items-center gap-2 min-w-0">
          <div className="text-2xl text-gray-500">
            <i className="bi-volume-up-fill" />
          </div>
          <div className="grow leading-none min-w-0">
            <div className="font-semibold truncate"
              title="Is That Alright (Lady Gaga Cover)"
            >
              Is That Alright (Lady Gaga Cover)
            </div>
            <div className="text-xs text-gray-300 truncate"
              title="Shannon Chung">
              Shannon Chung
            </div>
          </div>
        </div>
        <div className="relative h-1">
          <div className="absolute top-0 left-0 rounded-full bg-gray-700 w-full h-full"></div>
          <div
            className="absolute top-0 left-0 rounded-full bg-orange-800 h-full"
            style={{ width: Math.round(playPercent * 100) + "%" }}
          ></div>
          <button
            className="absolute top-0 left-0 w-full h-full"
            title="Seek"
            disabled={!loaded}
            onClick={handleSeekClick}></button>
        </div>
        <div className="flex gap-2 justify-center items-center">
          <button className="btn-music-control"
            title="Backward"
            onClick={() => {
              if (audioRef.current) audioRef.current.currentTime = 0
            }}
          >
            <i className="bi-skip-backward-fill"
            />
          </button>
          <button
            className="btn-music-control text-3xl"
            title="Play"
            onClick={() => dispatch(musicActions.toggleIsPlaying())}>
            {playing
              ? <i className="bi-pause-fill" />
              : <i className="bi-play-fill" />
            }
          </button>
          <button className="btn-music-control"
            title="Forward"
            disabled
          >
            <i className="bi-skip-forward-fill" />
          </button>
        </div>
      </div>
    </div>
    {/* <button className="text-center text-sm py-2 hover:bg-white/30">
      View Music Collection
    </button> */}
  </div>
}