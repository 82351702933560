import { SplashContact } from "../components/SplashContact"
import { SplashMain } from "../components/SplashMain"
import { SplashPrograms } from "../components/SplashPrograms"
import { SplashServices } from "../components/SplashServices"

export function Home() {
  return <div className="">
    <SplashMain />
    <SplashServices />
    <SplashPrograms />
    <SplashContact />
  </div>
}