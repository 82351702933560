import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import "bootstrap-icons/font/bootstrap-icons.css"
import "./App.css"

import store from "./store/store"
import { MainLayout } from "./components/MainLayout"
import { BreakpointProvider } from "./components/BreakpointProvider"

export function App() {
  return <div className="App">
    <Provider store={store}>
      <BreakpointProvider>
        <BrowserRouter>
          <MainLayout />
        </BrowserRouter>
      </BreakpointProvider>
    </Provider>
  </div>
}
