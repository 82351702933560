import { useEffect } from "react"
import { useMainPageBySlug } from "../lib/wp"

type WpPageProps = {
  slug?: string
  id?: string | number
}
export function WpPage({ slug }: WpPageProps) {
  const { data, error } = useMainPageBySlug(slug || null)
  const page = data?.[0] || null
  useEffect(() => {
    if (error) console.error(error)
  }, [error])
  // console.log(page)
  return <div className="WpPage text-gray-300 px-5">
    {page && <div className="container mx-auto">
      <h1 className="text-center my-5">
        {page.title.rendered}
      </h1>
      <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
    </div>}
  </div>
}