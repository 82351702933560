import { Link } from "react-router-dom";

export function PageFooter() {
  return <div className="bg-gray-900 text-white">
    <div className="container mx-auto py-10 px-3">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <figure className="inline-block">
            <img src="/assets/logo/ms-logo-final2-darkbg-20220423.png" className="h-14" alt="" />
            <figcaption className="text-gray-500 text-sm text-center block">www.MilleStudios.com</figcaption>
          </figure>
        </div>
        <div>
          <div className="grid text-lg">
            <Link className="link" to="/contact">
              <i className="bi bi-envelope" /> Contact us
            </Link>
            <a className="link" target="_blank" rel="noopener noreferrer" href="https://blog.millestudios.com">
              <i className="bi bi-blockquote-left" /> Blog
            </a>
            <a className="link" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/mille.studios.vancouver/">
              <i className="bi bi-instagram" /> Instagram &middot; mille.studios.vancouver
            </a>
          </div>
        </div>
      </div>
      <div className="h-24 lg:h-0"></div>
    </div>
  </div>
}