import axios from "axios"
import produce from "immer"
import { useCallback, useEffect, useRef, useState } from "react"
import validator from "validator"
import { useRecaptcha } from "../lib/recaptcha"

const fields = [
  { key: "contactName", label: "Your name" },
  { key: "email", label: "Email address" },
  { key: "phone", label: "Phone" },
  { key: "preferredContact", label: "Preferred contact method" },
  { key: "reason", label: "Reason for contact" },
  { key: "message", label: "Leave a message" },
] as const

type FieldKey = (typeof fields[number])["key"]
const fieldKeys: FieldKey[] = fields.map(field => field.key)

const defaultInputData = fieldKeys.reduce((acc, key) => {
  acc[key] = ""
  return acc
}, {} as Record<FieldKey, string>)

const POST_URL = process.env.NODE_ENV === "development"
  ? 'https://www.millestudios.com/contactSubmit.php'
  : '/contactSubmit.php'

export function ContactForm() {
  const recaptcha = useRecaptcha()
  const [status, setStatus] = useState<"success" | "error" | "sending" | null>(null)
  const [inputData, setInputData] = useState(defaultInputData)
  const [statusMessage, setStatusMessage] = useState("")
  const statusMessageRef = useRef<HTMLDivElement>(null)

  const handleSubmit = useCallback(async () => {
    setStatusMessage("Sending...")
    setStatus("sending")

    // Validate fields
    for (let field of fields) {
      if (validator.isEmpty(inputData[field.key])) {
        setStatusMessage(`Please fill in field: ${field.label}`)
        setStatus("error")
        return
      }
    }

    if (!validator.isEmail(inputData["email"])) {
      setStatusMessage(`Please enter a valid email address`)
      setStatus("error")
      return
    }

    if (!recaptcha) {
      setStatusMessage("ReCaptcha protection not ready. Please try again.")
      setStatus("error")
      return
    }
    const token = await recaptcha.execute("submit")

    const res = await axios.post(POST_URL, {
      ...inputData,
      "g-recaptcha-response": token,
    })
    if (res.data["result"] !== "success") {
      console.log(res.data)
      setStatusMessage("An error has occurred. Please try again.")
      setStatus("error")
      return
    }
    setStatusMessage("Message sent.")
    setStatus("success")
    setInputData(defaultInputData)
  }, [inputData, recaptcha])

  const handleChangeFactory = useCallback((key: FieldKey) => {
    return (e: { target: { value: string } }) => {
      setInputData(produce(draft => { draft[key] = e.target.value }))
    }
  }, [])

  useEffect(() => {
    if (!statusMessageRef.current) return
    statusMessageRef.current.scrollIntoView({ "block": "nearest" })
  }, [statusMessage])

  return <div className="grid gap-5">
    <div className="grid gap-1">
      <div className="text-lg">Your name</div>
      <input type="text" value={inputData["contactName"]} onChange={handleChangeFactory("contactName")} />
    </div>
    <div className="grid gap-1">
      <div className="text-lg">Email address</div>
      <input type="text" value={inputData["email"]} onChange={handleChangeFactory("email")} />
    </div>
    <div className="grid gap-1">
      <div className="text-lg">Phone number</div>
      <input type="text" value={inputData["phone"]} onChange={handleChangeFactory("phone")} />
    </div>
    <div className="grid gap-1">
      <div className="text-lg">Preferred contact method</div>
      <div className="flex flex-wrap items-center gap-3">
        <span>
          <input
            type="radio" name="contact-method" id="radio-phone" value="phone"
            checked={inputData["preferredContact"] === "phone"}
            onChange={handleChangeFactory("preferredContact")}
          /> <label htmlFor="radio-phone">Phone</label>
        </span>
        <span>
          <input
            type="radio" name="contact-method" id="radio-email" value="email"
            checked={inputData["preferredContact"] === "email"}
            onChange={handleChangeFactory("preferredContact")}
          /> <label htmlFor="radio-email">Email</label>
        </span>
      </div>
    </div>
    <div className="grid gap-1">
      <div className="text-lg">Reason for contact</div>
      <select
        value={inputData["reason"]}
        onChange={handleChangeFactory("reason")}
      >
        <option value="">Select a reason...</option>
        <option>General query</option>
        <option>Book a lesson</option>
        <option>Other</option>
      </select>
    </div>
    <div className="grid gap-1">
      <div className="text-lg">Leave a message</div>
      <div className="text-sm">If you're looking to book a lesson, leave us a message with your preferred availabilities. Although it may not be your confirmed lesson times, it will help us organize our schedules more efficiently.</div>
      <textarea rows={5}
        value={inputData["message"]} onChange={handleChangeFactory("message")}
      />
    </div>
    <div className="grid gap-1">
      <button
        disabled={status === "sending"}
        className="btn-outline-white"
        onClick={handleSubmit}><i className="bi bi-send" /> Send</button>
    </div>
    {status && <div
      ref={statusMessageRef}
      className={`text-center py-2 px-3 rounded flex items-center ${status === "success" ? "bg-green-800 text-green-200" : ""} ${status === "sending" ? "bg-gray-800 text-gray-200" : ""} ${status === "error" ? "bg-red-800 text-red-200" : ""}`}>
      {status === "success" && <i className="bi bi-check text-3xl" />}
      {status === "error" && <i className="bi bi-x text-3xl" />}
      {statusMessage}
    </div>}
  </div>
}