import { ContactForm } from "../components/ContactForm"
import { RecaptchaContext, useRecaptchaInstance } from "../lib/recaptcha"

export function Contact() {
  const recaptcha = useRecaptchaInstance()
  return <div className="text-gray-300 container mx-auto">
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div className="px-3">
        <h1>Book Today.</h1>
        <p>
          Looking to book your time? Fill out the contact form and we will schedule with you a complimentary 30-minute interview to confirm we are a fit.
        </p>
      </div>
      <div className="px-3 mb-5">
        <h1>Contact Us</h1>
        <RecaptchaContext.Provider value={recaptcha}>
          <ContactForm />
        </RecaptchaContext.Provider>
      </div>
    </div>
  </div>
}