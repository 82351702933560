import { Link } from "react-router-dom"
import art from "../assets/art/Music_Journey_20220519.jpg"
import { FadeInView } from "./FadeInView"
import { Parallax } from "./Parallax"

export function SplashServices() {
  return <>
    <div className="relative">
      <Parallax
        // inWindow={true}
        // inWindow={false}
        // direction="up"
        direction="down"
        wrapperHeight={"100vh"}
        childHeight={"125vh"}
      >
        <div
          className="h-full bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${art})`,
          }}>
        </div>
      </Parallax>
      <div className="absolute top-0 left-0 w-full h-full text-white">
        <div className="flex items-center justify-center h-full">
          <FadeInView>
            <div className="px-8 py-5 bg-black/70">
              <div className="flex flex-col gap-5 items-center">
                <div className="text-5xl text-center">
                  Find your potential.
                </div>
                <Link to="/how-we-help" className="btn-outline-white">How We Help</Link>
              </div>
            </div>
          </FadeInView>
        </div>
      </div>
    </div>
  </>
}