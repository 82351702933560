import axios from "axios"
import useSWR from "swr"

// Ref: https://www.cloudways.com/blog/use-react-with-wordpress-to-create-headless-cms/


const baseUrl = "https://blog.millestudios.com"

export async function fetchWp(url: string) {
  const response = await axios.get(url)
  return response.data
}

export function useWpGet(url: string | null) {
  return useSWR(url, fetchWp)
}

export function useMainPages() {
  return useWpGet(`${baseUrl}/wp-json/wp/v2/main_pages?context=embed`)
}

export function useMainPageBySlug(slug: string | null) {
  return useWpGet(slug && `${baseUrl}/wp-json/wp/v2/main_pages?slug=${slug}`)
}