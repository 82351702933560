import React, { HTMLProps, useCallback, useEffect, useRef, useState } from "react"
import { throttle } from "lodash"


type ParallaxProps = {
  children?: React.ReactNode
  wrapperProps?: HTMLProps<HTMLDivElement>
  childProps?: HTMLProps<HTMLDivElement>
  // /** Whether the bounds are within window or not (ie. from 0 to window height, or from 0 - wrapper height to window height + wrapper height) */
  // inWindow?: boolean
  /** The direction the children moves when scrolling down */
  direction?: "up" | "down"
  // /** Size multiplier of children, with 1.0 as base. This is how much the children move based on window height. */
  // ratio?: number
  wrapperHeight?: string | number
  childHeight?: string | number
}
export function Parallax({ wrapperProps, children, direction = "down", childHeight = "100vh", wrapperHeight = "100vh" }: ParallaxProps) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const childRef = useRef<HTMLDivElement>(null)
  const [y, setY] = useState(0)

  const update = useCallback(() => throttle(() => {
    /** 1 or -1 */
    const directionMult: number = direction === "up" ? -1 : 1

    const wrapper = wrapperRef.current
    const child = childRef.current
    if (!wrapper) return
    if (!child) return
    const wrapperHeight = wrapper.getBoundingClientRect().height
    const wrapperTop = wrapper.getBoundingClientRect().top
    const windowHeight = window.innerHeight
    const childDOMHeight = child.getBoundingClientRect().height
    // const childHeightBase = inWindow ? windowHeight : (windowHeight + wrapperHeight)
    // const childHeight = windowHeight * ratio
    // childHeightBase * ratio

    const topFrom = 0 - wrapperHeight
    const topTo = windowHeight
    const topRange = topTo - topFrom
    const offset = wrapperTop - topFrom
    const percent = 1 - (offset / topRange)


    // const childRange = childDOMHeight - wrapperHeight + (inWindow ? wrapperHeight : 0)
    const childDiff = childDOMHeight - wrapperHeight
    let childFrom = 0 - childDiff
    if (direction === "down") childFrom += 0 + childDiff
    let childTo = 0
    if (direction === "down") childTo += 0 - childDiff
    const childRange = childTo - childFrom
    const yOffset = childRange * percent * directionMult
    const initial = directionMult === 1 ? childFrom : childTo
    const y = initial + yOffset

    // // if (percent < 0) percent = 0
    // // if (percent > 1) percent = 1
    // const initial = directionMult === 1 ? 0 - (childDOMHeight - wrapperHeight) : 0
    // const y = initial + (percent * ((childDOMHeight - wrapperHeight) * directionMult))
    // console.log(childFrom, childTo, childRange, yOffset, y)

    // setChildHeight(childHeight)
    setY(y)
  }, 1000 / 60)(), [direction])

  useEffect(() => {
    window.addEventListener("scroll", update)
    window.addEventListener("resize", update)
    update()
    return () => {
      window.removeEventListener("scroll", update)
      window.removeEventListener("resize", update)
    }
  }, [update])

  return <div ref={wrapperRef}
    {...wrapperProps}
    className={`overflow-hidden ${wrapperProps?.className || ""}`}
    style={{
      height: wrapperHeight,
      ...wrapperProps?.style,
    }}
  >
    <div
      ref={childRef}
      style={{
        transform: `translateY(${y}px)`,
        height: childHeight,
      }}>
      {children}
    </div>
  </div>
}