import React, { useCallback, useContext } from "react";


export const breakpoints = ["xs", "sm", "md", "lg", "xl", "2xl"] as const
export type Breakpoint = typeof breakpoints[number]

export const breakpointContext = React.createContext<Breakpoint>("xs")


export function useBreakpoint() {
  const breakpoint = useContext(breakpointContext)
  /** Whether window is equal or larger than bp */
  const isBreakpoint = useCallback((bp: Breakpoint) => {
    const windowIndex = breakpoints.findIndex(bp => breakpoint === bp)
    const bpIndex = breakpoints.findIndex(breakpoint => bp === breakpoint)
    return (windowIndex >= bpIndex)
  }, [breakpoint])
  return { breakpoint, isBreakpoint }
}